// extracted by mini-css-extract-plugin
export var con = "search-module--con--157Tr";
export var menuBar = "search-module--menuBar--1OrrY";
export var info = "search-module--info--2R29j";
export var link = "search-module--link--BwpGG";
export var infoCon = "search-module--infoCon--d3AHt";
export var storyCon = "search-module--storyCon--gy3rZ";
export var imgCard = "search-module--imgCard--2hd2g";
export var video = "search-module--video--ncAH-";
export var videoImg = "search-module--videoImg--3JoxP";
export var text = "search-module--text--B6ls5";
export var title = "search-module--title--3-4z5";
export var menu = "search-module--menu--1g2Qe";
export var on = "search-module--on--26Wsq";
export var main = "search-module--main--e6T00";