import React, { useState, useEffect, useCallback } from "react"
import Layout from "components/common/layout.js"
import queryString from "query-string"
import * as styles from "./search.module.css"
import OrderMenu from "components/tools/orderMenu"
import { useMenu } from "hooks"
import Story from "components/story/story"
import Lesson from "components/post/lesson.js"
import { getThumb, getDateYMD } from "utils"
import Menu from "components/class/room/menu.js"
import peachIcons from "images/peachIcons.png"
import { menu } from "utils/data"
import { smallSliceIcon } from "images"
import Pagenation from "components/tools/pagination.js"
import { getSearchListAsync } from "store/modules/searchReducer.js"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { navigate } from "gatsby"
import NewBadge from "components/tools/newBadge"
const Index = props => {
  const { tag, keyword } = queryString.parse(props.location.search)
  const [{ current, previous }, setMenu, onMenu] = useMenu()
  const isAll = current?.getAttribute("id") === "all"
  const selectId = current?.getAttribute("id")
  const [listObj, setListObj] = useState({
    story: [],
    edunanum: [],
    illust: [],
    exhibit: [],
    edutalk: [],
    video: [],
  })

  const setList = (type, list) => {
    setListObj({
      ...listObj,
      [type]: list,
    })
  }

  console.log(current?.getAttribute("id"))

  return (
    <Layout>
      <div className={styles.con}></div>
      <MenuBar setMenu={setMenu} onMenu={onMenu} />
      {isAll || selectId === "story" ? (
        <List
          location={props.location}
          tag={tag}
          keyword={keyword}
          pagePostSize={8}
          isAll={isAll}
          type={"story"}
          setList={setList}
        >
          <div className={styles.storyCon} style={{ minHeight: "200px" }}>
            {listObj.story.map(post => {
              return (
                <div key={post.story_no}>
                  {/* <NewBadge registerTime={post.publishTime} /> */}
                  <Story
                    story_no={post.story_no}
                    title={post.title}
                    introText={post.introText}
                    thumb_title_uuid={post.thumb_title_uuid}
                    cateMain={post.cateMain}
                    cateSub={post.cateSub}
                    Like={post.Like}
                    free={post.free}
                    registerTime={post.publishTime}
                  />
                </div>
              )
            })}
          </div>
        </List>
      ) : null}
      {isAll || selectId === "edunanum" ? (
        <List
          location={props.location}
          tag={tag}
          keyword={keyword}
          pagePostSize={4}
          isAll={isAll}
          type={"edunanum"}
          setList={setList}
        >
          <div className={styles.storyCon}>
            {listObj.edunanum.map((post, i) => (
              <Lesson post={post} key={i} />
            ))}
          </div>
        </List>
      ) : null}
      {isAll || selectId === "illust" ? (
        <List
          location={props.location}
          tag={tag}
          keyword={keyword}
          pagePostSize={1}
          isAll={isAll}
          type={"illust"}
          setList={setList}
        >
          <div>
            {listObj.illust.map((post, i) => (
              <div
                key={post.illust_no}
                onClick={() => {
                  navigate(
                    `/visible/card/detail?illust_no=${
                      post.illust_no
                    }&currentPage=${1}&pagePostSize=${7}`
                  )
                }}
                className={styles.imgCard}
              >
                <img src={getThumb(post.thumb_title_uuid)} />
              </div>
            ))}
          </div>
        </List>
      ) : null}
      {isAll || selectId === "exhibit" ? (
        <List
          location={props.location}
          tag={tag}
          keyword={keyword}
          pagePostSize={3}
          isAll={isAll}
          type={"exhibit"}
          setList={setList}
        >
          <div>
            {listObj.exhibit.map((post, i) => (
              <Menu
                key={post.exhibit_no}
                img={getThumb(post.thumb_title_uuid)}
                title={post.title}
                text={post.introText}
                hit={post.hit}
                like={post.like}
                selfLike={post.Like}
                date={getDateYMD(post.publishTime, "YYYY.MM.DD")}
                writer="피치서가"
                onClick={() => {
                  navigate(
                    `/news/detail?exhibit_no=${
                      post.exhibit_no
                    }&currentPage=${1}&pagePostSize=${7}`
                  )
                }}
              />
            ))}
            <div></div>
          </div>
        </List>
      ) : null}
      {isAll || selectId === "edutalk" ? (
        <List
          location={props.location}
          tag={tag}
          keyword={keyword}
          pagePostSize={3}
          isAll={isAll}
          type={"edutalk"}
          setList={setList}
        >
          <div>
            {listObj.edutalk.map((post, i) => (
              <Menu
                key={post.talk_no}
                img={getThumb(post.thumb_title_uuid)}
                title={post.title}
                text={post.introText}
                hit={post.hit}
                like={post.like}
                selfLike={post.Like}
                date={getDateYMD(post.registerTime, "YYYY.MM.DD")}
                writer="피치서가"
                onClick={() => {
                  navigate(
                    `/class/room/detail/?talk_no=${
                      post.talk_no
                    }&currentPage=${1}&pagePostSize=${7}`
                  )
                }}
              />
            ))}
            <div></div>
          </div>
        </List>
      ) : null}
      {isAll || selectId === "video" ? (
        <List
          location={props.location}
          tag={tag}
          keyword={keyword}
          pagePostSize={3}
          isAll={isAll}
          type={"video"}
          setList={setList}
        >
          <div className={styles.main}>
            {listObj.video.map((post, i) => (
              <div key={post.video_no}>
                <div
                  onClick={() => {
                    navigate(
                      `/visible/media/detail?video_no=${
                        post.video_no
                      }&currentPage=${1}&pagePostSize=${6}&idx=${i + 1}`
                    )
                  }}
                  className={styles.video}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={getThumb(post.thumb_title_uuid)}
                    className={styles.videoImg}
                  />
                  <div
                    className={styles.text}
                    style={{
                      gap: "17px",
                    }}
                  >
                    <img
                      src={peachIcons}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginTop: "5px",
                      }}
                    />
                    <div>
                      <div>{post.title}</div>
                      <div>{post.introText}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div></div>
          </div>
        </List>
      ) : null}
    </Layout>
  )
}

export default Index
const List = ({
  tag,
  keyword,
  pagePostSize,
  isAll,
  type,
  children,
  setList,
  location,
}) => {
  const { page } = queryString.parse(location.search)
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const [order, setOrder] = useState(1)
  const dispatch = useDispatch()
  const getSearchList = useCallback(
    (page, take, order, type, tagNumber, keyword) =>
      dispatch(getSearchListAsync(page, take, order, type, tagNumber, keyword)),
    [dispatch]
  )

  useEffect(() => {
    ;(async () => {
      await getSearchList(currentPage, pagePostSize, order, type, tag, keyword)
    })()
  }, [tag, keyword, order, currentPage, type])

  const searchReducer = useSelector(
    ({ searchReducer }) => searchReducer,
    shallowEqual
  )

  const { [`${type}List`]: List, [`${type}Total`]: total } = searchReducer[type]

  useEffect(() => {
    setList(type, List)
  }, [List])

  console.log()

  const types = !keyword ? "전체" : keyword

  return (
    <div style={{ minHeight: "200px" }}>
      {!isAll ? (
        <OrderMenu
          order={order}
          setOrder={setOrder}
          style={{ marginTop: "20px" }}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <div style={{ marginTop: "72.2px" }} />
      )}

      <div className={styles.infoCon}>
        <div className={styles.info}>
          <span>'{types}'</span> 관련 {menu.get(type)}
          <span>({total})</span>
        </div>
        <div
          className={styles.link}
          onClick={() => {
            document.getElementById(`${type}`).click()
          }}
        >
          {isAll ? (
            <>
              <span>'{types}'</span> 관련 {menu.get(type)} 전체보기{" "}
              <img src={smallSliceIcon} />
            </>
          ) : null}
        </div>
      </div>
      {children}
      {!isAll ? (
        <>
          <div style={{ marginTop: "30px" }} />
          <Pagenation
            total={total}
            pagePostSize={pagePostSize}
            pageSize={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      ) : null}
    </div>
  )
}
const MenuBar = props => {
  useEffect(() => {
    document.getElementById("all").click()
  }, [])

  const onMenu = ({ currentTarget }) => {
    props.setMenu(currentTarget)

    props.onMenu(curr => {
      curr.style.borderTop = "2px solid #E95C63"

      return prev => {
        prev.style.borderTop = "1px solid #dfdfdf"
      }
    })
  }

  const { story, edunanum, illust, exhibit, edutalk, video } = useSelector(
    ({ searchReducer }) => searchReducer,
    shallowEqual
  )
  const totals = {
    all:
      story.storyTotal +
      edunanum.edunanumTotal +
      illust.illustTotal +
      exhibit.exhibitTotal +
      edutalk.edutalkTotal +
      video.videoTotal,
    story: story.storyTotal,
    edunanum: edunanum.edunanumTotal,
    illust: illust.illustTotal,
    exhibit: exhibit.exhibitTotal,
    edutalk: edutalk.edutalkTotal,
    video: video.videoTotal,
  }

  return (
    <div className={styles.menuBar}>
      {Array.from(menu).map(([key, val], i) => (
        <div id={key} key={i} onClick={onMenu}>
          {val}
          <span>({totals[key]})</span>
        </div>
      ))}
    </div>
  )
}
